import { LocalizationProvider as MUILocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { enUS, svSE } from "@mui/x-date-pickers/locales";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import storage from "../../utils/store/storage";
import i18n, { translate as defaultTranslate } from "./i18n";
import { DEFAULT_LANGUAGE, LANGUAGES } from "./locales";

const LANGUAGE_SAVE_ID = "LANGUAGE";

interface LocalizationInterface {
  setLanguage: (language: string) => void;
  language: string;
  translate: typeof defaultTranslate;
}

const defaultLocalization: LocalizationInterface = {
  setLanguage: (_language: string) => {},
  language: DEFAULT_LANGUAGE,
  translate: defaultTranslate,
};

const customSvLocale = {
  ...svSE,
  components: {
    ...svSE.components,
    MuiLocalizationProvider: {
      ...svSE.components.MuiLocalizationProvider,
      defaultProps: {
        ...svSE.components.MuiLocalizationProvider.defaultProps,
        localeText: {
          ...svSE.components.MuiLocalizationProvider.defaultProps.localeText,
          clearButtonLabel: "Rensa",
          fieldClearLabel: "Rensa",
        },
      },
    },
  },
};

const customEnLocale = {
  ...enUS,
  components: {
    ...enUS.components,
    MuiLocalizationProvider: {
      ...enUS.components.MuiLocalizationProvider,
      defaultProps: {
        ...enUS.components.MuiLocalizationProvider.defaultProps,
        localeText: {
          ...enUS.components.MuiLocalizationProvider.defaultProps.localeText,
          clearButtonLabel: "Clear",
          fieldClearLabel: "Clear",
        },
      },
    },
  },
};

export const LocalizationContext = createContext<LocalizationInterface>(defaultLocalization);

export const LocalizationProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState("");
  const translate = useTranslation("translation").t;

  const setChosenLanguage = (language: string) => {
    changeLanguage(language);
    setLanguage(language);
    saveLanguage(language);
  };

  useEffect(() => {
    const initializeLanguage = async () => {
      const currentLanguage = (await storage.loadItem(LANGUAGE_SAVE_ID)) as string | undefined;

      if (currentLanguage) {
        setChosenLanguage(currentLanguage);
      } else {
        const language = navigator.language.split(/[-_]/)[0];

        setChosenLanguage(language ?? DEFAULT_LANGUAGE);
      }
    };

    initializeLanguage();
  }, []);

  return (
    <LocalizationContext.Provider value={{ setLanguage: setChosenLanguage, language, translate }}>
      <MUILocalizationProvider
        adapterLocale={language}
        dateAdapter={AdapterDayjs}
        localeText={getCustomLocaleText(language)}
      >
        {children}
      </MUILocalizationProvider>
    </LocalizationContext.Provider>
  );
};

function saveLanguage(language?: string, rememberMe?: boolean) {
  storage.saveItem(LANGUAGE_SAVE_ID, rememberMe ?? false, language);
}

function changeLanguage(language: string) {
  i18n.changeLanguage(language);
}

function getCustomLocaleText(language: string) {
  if (language === LANGUAGES.sv_SE) return customSvLocale.components.MuiLocalizationProvider.defaultProps.localeText;
  if (language === LANGUAGES.en_US) return customEnLocale.components.MuiLocalizationProvider.defaultProps.localeText;
}
