import { IconButton, TextField, Tooltip } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import { translate } from "../../../../preferences/localization";
import { InputProps } from "./dataTypes";

const InputField = ({ customCssClass, showClearButton, onClear, ...rest }: InputProps) => {
  const [shrinkLabel, setShrinkLabel] = useState(false);

  useEffect(() => {
    if (rest.value !== "" && rest.value != null) {
      setShrinkLabel(true);
    }
  }, [rest.value]);

  const handleClear = useCallback(() => {
    onClear?.();
    setShrinkLabel(false);
  }, [onClear]);

  const handleFocus = useCallback(() => {
    setShrinkLabel(true);
  }, [setShrinkLabel]);

  return (
    <TextField
      className={customCssClass}
      {...rest}
      onFocus={handleFocus}
      onBlur={e => e.target.value === "" && setShrinkLabel(false)}
      InputProps={{
        ...rest.InputProps,
        endAdornment:
          rest.value != null && rest.value !== "" && showClearButton ? (
            <IconButton onClick={handleClear}>
              <Tooltip title={translate("CLEAR")}>
                <ClearIcon sx={{ cursor: "pointer" }} />
              </Tooltip>
            </IconButton>
          ) : (
            rest.InputProps?.endAdornment
          ),
      }}
      InputLabelProps={{ shrink: shrinkLabel }}
      sx={{
        ...(showClearButton
          ? {
              "& .MuiButtonBase-root": {
                opacity: 0,
                transition: "opacity 0.3s ease-in-out",
              },
              "&:hover .MuiButtonBase-root": {
                opacity: 1,
              },
            }
          : {}),
        '& input[type="search"]::-webkit-search-cancel-button': {
          opacity: 0,
        },
        '&:hover input[type="search"]::-webkit-search-cancel-button': {
          opacity: 0,
        },
        ...rest.sx,
      }}
    />
  );
};

export default InputField;
